import { getCurrentOffice } from "../selectors/offices";

const invoiceConfigurationSchema = function(state, props) {
  let office = getCurrentOffice(state)
  let freeFromEnterprise = office.hiddenPreferences && office.hiddenPreferences.includes("freeFormEnterpriseNumber")
  return {
    "enterpriseNumber": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "MaskedTextWidget",
      "ui:options" : {
        mask: freeFromEnterprise ? false : [/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]
      }
    },
    "iban": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "MaskedTextWidget",
      "ui:options" : {
        mask:  function(rawValue) {
          let beMask = ['B', 'E']
          let otherCountryMask = [/[a-zA-Z]/, /[a-zA-Z]/]
          let usedCountryMask = (isNaN(rawValue.charAt(0))) ? otherCountryMask : beMask

          let beDigitsMask = [ /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
          let nlDigitsMask = [ /\d/, /\d/, ' ', /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/]
          let otherDigitsMask = new Array(Math.max(rawValue.replace(/_/g, '').length - 2, 0)).fill(/[a-zA-Z0-9 ]/)
          let usedDigitsMask = beDigitsMask

          if(rawValue.length >= 2 && !rawValue.toUpperCase().startsWith('BE')){
            if (rawValue.toUpperCase().startsWith('NL')) {
              usedDigitsMask = nlDigitsMask
            } else{
              usedDigitsMask = otherDigitsMask
            }
          }

          return usedCountryMask.concat(usedDigitsMask)
        },
        pipe: function(value){
          return value.toUpperCase()
        }

      }
    },
    "invoiceConditions": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "DraftWidget"
    },
    "invoiceMessage": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "DraftWidget"
    },
    "startInvoiceIdentification": {
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:widget": "UpDownWidget",
      "ui:options": {
        width: 2
      },
    }
  }
}

export default invoiceConfigurationSchema;
