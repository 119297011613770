import { setGlobalError } from 'actions/errors'


import { deleteRequest, getJson, postJson, putJson } from 'api_helpers'
import denormalize from 'denormalize';

import { flattenEntities, flattenEntity } from 'flatten_entity'

import isUuid from 'is_uuid'

import normalize from 'json-api-normalizer';
import _ from 'lodash';
import pluralize from 'pluralize'

import { entityApiRoute, newEntityApiRoute } from 'route_helpers'
import underscored from 'underscore.string/underscored'

import uuid from 'uuid'
import { fetchContact } from "./contacts";
import { createContactWithDefaults } from "./contacts";
import { createDossierWithDefaults } from "./dossiers";

/* THUNKS - SERVER INTERACTIONS */

/* FETCHING */

export function refreshEntities(entitiesName, keyWindow ={}, extraEntitiesToDelete =[]) {
  let entityName = pluralize.singular(entitiesName)
  return function(dispatch) {
    extraEntitiesToDelete.forEach(ntt => dispatch(deleteAllEntities(ntt)))
    dispatch(deleteAllEntities(entityName))
    dispatch(fetchEntities(entitiesName, keyWindow))
  }
}

export function fetchEntity(entitiesName, entityId){
  if (entitiesName === 'contacts') return fetchContact(entityId)

  let entityName = pluralize.singular(entitiesName)
  return fetchEntities(entitiesName, {name: entityName, filter: {id: entityId}, page: 1})
}
export function fetchEntities(entitiesName, keyWindow = {}) {
  return function(dispatch, getState) {
    // Since redux-thunk 2.1.0 we can inject an argument, we could use this for api_type
    const api_type = getState().api_type;

    // if(getState().entities[entityName].preloaded) {
    //   return Promise.resolve()
    // }

    dispatch(requestEntities(entitiesName))
    let extraParams = {}
    if (keyWindow && keyWindow.page) {
      extraParams = { page: keyWindow.page, ...keyWindow.filter}
    }

    return getJson(entityApiRoute(entitiesName, api_type, extraParams)).then (
      response => response.json(),
      error => dispatch(fetchEntitiesFailure(entitiesName))
    ).then (
      json => {
        if(json.errors) {
          dispatch({type: 'UPDATE_UI_STATE' , payload: { key: 'mainModal', name: 'isMainModalVisible', value: true  }} )
        } else {
          var normalizedJson = normalize(json)

          if (Object.keys(normalizedJson).length > 0) {
            Object.keys(normalizedJson).forEach( (key) => {
              if (key === entitiesName) {
                let receiveWindow = {}
                let paginationInfo = (json.meta && json.meta.pagination  || {} )
                if (keyWindow) {
                  receiveWindow = {...keyWindow, ...paginationInfo}
                }

                dispatch(receiveEntities(entitiesName))
                dispatch(bulkUpsert(key, normalizedJson[key], receiveWindow))
              } else {
                dispatch(receiveEntities(entitiesName))
                dispatch(bulkUpsert(key, normalizedJson[key]))
              }
            })
          } else {
            dispatch(receiveEntities(entitiesName))
            dispatch(bulkUpsert(entitiesName, {}))
          }
        }
      }
    )
  }
}

export function bulkCreate(entitiesName, normalizedReduxObjects, keyWindow = null) {
  let entityName = pluralize.singular(entitiesName)
  return {
    type: `BULK_CREATE_${underscored(entityName).toUpperCase()}`,
    payload: flattenEntities(normalizedReduxObjects),
    keyWindow:  keyWindow,
  }
}

export function bulkUpsert(entitiesName, normalizedReduxObjects, keyWindow = null) {
  let entityName = pluralize.singular(entitiesName)
  return {
    type: `BULK_UPSERT_${underscored(entityName).toUpperCase()}`,
    payload: flattenEntities(normalizedReduxObjects),
    keyWindow:  keyWindow,
  }
}

export function updateEntity(entityName, entity) {
  return {
    type: `UPDATE_${underscored(entityName).toUpperCase()}`,
    payload: entity
  }
}

export function deleteEntity(entityName, id, soft = true) {
  return {
    type: `DELETE_${underscored(entityName).toUpperCase()}`,
    payload: { id: id, soft: soft }
  }
}

export function deleteAllEntities(entityName) {
  return {
    type: `DELETE_ALL_${underscored(entityName).toUpperCase()}`
  }
}

export function deleteAllNewEntities(entitiesName) {
  let entityName = pluralize.singular(entitiesName)
  return {
    type: `DELETE_ALL_NEW_${underscored(entityName).toUpperCase()}`
  }
}




/* UPDATE AND CREATE REMOTE */

export function newEntityFromApi(entityName, entity = { attributes: {}, relationships: {}}) {
  let entitiesName = pluralize(entityName)
  return function(dispatch, getState) {

    let body = {
      data: entity,
      type: entitiesName,
      included: []
    }


    return postJson(newEntityApiRoute(entitiesName), body).then( (response) => {
      return response.json()
    }).then ( (json) => {
      var normalizedJson = normalize(json)
      var newEntityFromApi = Object.values(normalizedJson[entitiesName])[0]
      newEntityFromApi.id = (Math.min(...getState().entities[entityName].allIds,0) - 1).toString()
      dispatch(createEntity(entityName, newEntityFromApi ))
      return newEntityFromApi.id
    })

  }
}

export function upsertEntity(entityName, id, upsertedRelationships = [], resourceName = null) {
  if (!id) {
    throw `Trying to upsert entity ${entityName} without id`
  }

  var resourceNameToUse = resourceName || entityName

  var resourcesName = pluralize(resourceNameToUse)


  var entitiesName = pluralize(entityName)

  return function(dispatch, getState) {

    var entity = denormalize(getState().entities, entityName, id, upsertedRelationships)
    var api_type = getState().api_type

    if(!entity) {
      throw `Entity not found: ${entitiesName} ${id}`
    }

    dispatch(setEntityFormPending(entityName,id))
    dispatch(clearAllEntityErrors())

    var upsertPromise = null

    if (isUuid(id)) {
      upsertPromise = postJson(entityApiRoute(resourcesName, api_type), entity)
    } else {
      upsertPromise = putJson(entityApiRoute(resourceNameToUse, api_type, {id: id}), entity)
    }

    return upsertPromise.then (
      response => {
        let json = response.json()
        if(response.status >= 500) {

          if( json && json.errors && json.errors[0]) {
            dispatch(setGlobalError(json.errors[0].detail))
          } else {
            dispatch(setGlobalError(response.statusText))
          }
          throw new Error("createFailed")
        } else {
          return json
        }
      },
      error => dispatch(upsertEntityFailure(error))
    ).then (
      json => {
        if (!json.errors) {
          var normalizedJson = normalize(json)
          Object.keys(normalizedJson).forEach( (key) => dispatch(receiveEntities(key, normalizedJson[key])))
          Object.keys(normalizedJson).forEach( (key) => dispatch(bulkUpsert(key, normalizedJson[key])))
         // Object.keys(normalizedJson).forEach( (key) => dispatch(deleteAllNewEntities(key)))

          dispatch(setEntityFormSubmitted(entityName,id))

          setTimeout( () => { dispatch(clearAllEntityForms()) }, 1000)

          return (Object.keys(normalizedJson[entitiesName]) || [])[0]
        } else {

          if (json.errors[0] && json.errors[0].status === "403") {
            dispatch({type: 'UPDATE_UI_STATE' , payload: { key: 'mainModal', name: 'isMainModalVisible', value: true  }} )
          } else {
            dispatch(upsertEntityFailure(json.errors))
            dispatch(setEntityFormSubmitFailed(entityName,id))
            setTimeout( () => { dispatch(clearAllEntityForms()) }, 1000)
          }
        }
      }
    )
  }
}

/* DESTROY ENTITY */
export function destroyEntity(entityName, id) {
  var entityName = entityName
  return function(dispatch, getState) {

    var api_type = getState().api_type
    if (isUuid(id)) {
      dispatch(deleteEntity(entityName, id))
    }
    else {

      if(!getState().entities[entityName]) {
        throw `Entities ${entityName} not found`;
      }

      if(!getState().entities[entityName].byId[id]) {
        return
      }


      return deleteRequest(entityApiRoute(underscored(entityName), api_type,  {id: id})).then (
        response => response.json(),
        error => dispatch(destroyEntityFailure(entityName, id, json.errors))
      ).then (
        json => {
          if (!json.errors) {
            dispatch(deleteEntity(entityName, id))
          } else {
            dispatch(destroyEntityFailure(entityName, id, json.errors))
          }
        }
      )
    }
  }
}


/* STORE INTERACTIONS - REGULAR ACTIONS */
// Return action creators
export function requestEntities(entitiesName) {
  return {
    type: `REQUEST_${underscored(entitiesName).toUpperCase()}`
  }
}

export function replaceEntities(entityName, oldEntities, entities, keyWindow = null) {
  return {
    type: `BULK_REPLACE_${underscored(entityName).toUpperCase()}`,
    payload: { entities: entities, oldEntities: oldEntities}
  }
}

export function fetchEntitiesFailure(entitiesName) {
  return function(error) {
    return {
      type: `FETCH_${underscored(entitiesName).toUpperCase()}_FAILURE`,
      payload: error
    }
  }
}

export function filterEntities(entitiesName, filter) {
  return {
    type: `FILTER_${underscored(entitiesName).toUpperCase()}`,
    payload: filter
  }
}

export function filterEntitiesBy(entitiesName, by, filter) {
  return {
    type: `FILTER_${underscored(entitiesName).toUpperCase()}_BY`,
    payload: {by: by, filter: filter}
  }
}

export function clearNewEntities() {
  return {
    type: `CLEAR_ALL_NEW`,
  }
}

export function clearAllEntityForms() {
  return {
    type: `CLEAR_ALL_FORMS`,
    payload: {}
  }
}

export function clearEntityForm(entityName,id) {
  return {
    type: `CLEAR_FORM_${underscored(entityName).toUpperCase()}`,
    payload: { id: id }
  }
}

export function receiveEntityForm(entityName,id, form) {
  return {
    type: `RECEIVE_FORM_${underscored(entityName).toUpperCase()}`,
    payload: { id: id, form: form }
  }
}

export function setEntityFormPending(entityName, id) {
  return function(dispatch, getState) {
    return dispatch(receiveEntityForm(entityName, id, { state: 'pending' }))
  }
}

export function setEntityFormSubmitted(entityName, id) {
  return function(dispatch, getState) {
    return dispatch(receiveEntityForm(entityName, id, { state: 'submitted' }))
  }
}

export function setEntityFormSubmitFailed(entityName, id) {
  return function(dispatch, getState) {
    return dispatch(receiveEntityForm(entityName, id, { state: 'submitFailed' }))
  }
}

export function destroyEntityFailure(entityName, id , errors) {
  var entitiesName = pluralize(entityName)
  return function(dispatch) {
      errors.forEach( (error) => {
        if(!error.attribute) {
          throw 'error has no attribute specified'
        }
        dispatch(receiveEntityError(error.entity, error.entity_id, error.attribute, error.detail))
      }
    )
  }
}

export function upsertEntityFailure(errors) {
  return function(dispatch) {
      errors.forEach( (error) => {
        if(!error.attribute) {
          throw 'error has no attribute specified'
        }
        dispatch(receiveEntityError(error.entity, error.entity_id, error.attribute, error.detail))
      }
    )
  }
}

/* Errors */
export function receiveEntityError(entityName, id, model, error) {
  return {
    type: `RECEIVE_ERROR_${underscored(entityName).toUpperCase()}`,
    payload: { id: id, model: model, error: error}
  }
}

export function clearEntityErrors(entityName, id) {
  return {
    type: `CLEAR_ERRORS_${underscored(entityName).toUpperCase()}`,
    payload: { id: id }
  }
}

export function clearAllEntityErrors() {
  return {
    type: `CLEAR_ALL_ERRORS`,
  }
}
/* New */

export function createEntity(entityName, entity = {}) {
  return function(dispatch, getState) {
    let payload = {...entity,...{ id: uuid.v4()} }
    dispatch({
      type: `CREATE_${underscored(entityName).toUpperCase()}`,
      payload: payload
    })

    return payload.id
  }
}

export function createEntityWithDefaults(entityName, entity = {}, include_nested = true) {
  if (include_nested){
    if(entityName === 'contact') return createContactWithDefaults(entity)
    if(entityName === 'dossier') return createDossierWithDefaults(entity)
  }

  var entitiesName = pluralize(entityName)

  return function(dispatch, getState) {
    var resource = getState().resources.list.find((resource) => resource["attributes"].title === entityName);

    if(!resource) {
      throw `The resource ${entityName} is not found in the resources fetched from the api`
    }

    var defaults = flattenEntity(resource.attributes.defaults || {})
    var entityWithDefaults = _.merge({}, defaults, entity)
    return dispatch(createEntity(entityName, entityWithDefaults))
  }
}

export function createEntityWithRelationships(entityName, entityDesignation, attributes= {}, receivedRelationships = {}) {
  var entitiesName = pluralize(entityName)

  var relationships = {}
  Object.keys(receivedRelationships).forEach( (relationshipEntityName) => {
    let relationship = receivedRelationships[relationshipEntityName]
    relationships[relationshipEntityName] = { data: relationship }
  })
  return function(dispatch, getState) {
    var newId = (Math.min(...getState().entities[entitiesName].allIds,0) - 1).toString()
    var entity = { id: newId, relationships: relationships, attributes: attributes }
    dispatch(createEntityWithDefaults(entityName, entity))


    Object.keys(receivedRelationships).forEach( (relationshipEntityName) => {
      let relationship = receivedRelationships[relationshipEntityName]
      dispatch(receiveRelationship(entityDesignation, entityName, newId, relationshipEntityName, relationship.id))
    })

    return newId
  }
}

export function createEntityWithRelationship(entityName, entityDesignation, relationshipEntityName, relationshipEntityId) {
  return function(dispatch) {
    let entityId = dispatch(createEntityWithDefaults(entityName))
    let entity = dispatch(updateEntity(relationshipEntityName, { id: relationshipEntityId,  [`${entityDesignation}Id`]:  entityId }))
  }
}

/* RECEIVING */

export function receiveEntity(entityName, entity) {
  var entitiesName = pluralize(entityName)
  return {
    type: `RECEIVE_${underscored(entitiesName).toUpperCase()}`,
    payload: { [entity.id]: entity }
  }
}

export function receiveEntities(entitiesName) {
  return {
    type: `RECEIVE_${underscored(entitiesName).toUpperCase()}`
  }
}

export function receiveRelationships(entityName, entityId) {
  var entitiesName = pluralize(entityName)
  return function(dispatch, getState) {
    var entity = getState().entities[entitiesName].byId[entityId]
    Object.keys(entity.relationships).forEach( (relationshipEntityName) => dispatch(receiveRelationship(entityName, entityId, relationshipEntityName, entity.relationships[relationshipEntityName].data.id) ) )
  }
}

export function receiveRelationship(entityDesignation, entityName, entityId, relationshipEntityName, relationshipEntityId) {
  var relationships = {}
  var entitiesName = pluralize(entityName)
  relationships[entityDesignation] = { data: { id: entityId, type: entitiesName }}
  var payload = { id: relationshipEntityId, relationships: relationships }
  return {
    type: `RECEIVE_RELATIONSHIP_${underscored(relationshipEntityName).toUpperCase()}`,
    payload: payload
  }
}

/* CHANGING */

export function changeEntity(entityName, entity = { attributes: {}, relationships: {}}) {
  return {
    type: `CHANGE_${underscored(entityName).toUpperCase()}`,
    payload: entity
  }
}

/* META */

export function editEntity(entityName, id) {
  return {
    type: `EDIT_${underscored(entityName).toUpperCase()}`,
    payload: id
  }
}
/* COPY */

export function copyEntity(entityName, id) {
  return function(dispatch, getState) {
    let payload = {id: id, newId: uuid.v4()}
    dispatch({
      type: `COPY_${underscored(entityName).toUpperCase()}`,
      payload: payload
    })

    return payload.newId
  }

}
